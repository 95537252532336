
export async function getData(api, url = '', auth_token, data) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: api, // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth_token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    const param = response.json()
    return param// parses JSON response into native JavaScript objects
}

export async function getUserInformation(api, url = '', auth_token) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: api, // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth_token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
  const param = response.json()
  return param// parses JSON response into native JavaScript objects
}

export default getData
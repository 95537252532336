
import mock from '../mock'
import getData from '../apiCall'


mock.onGet('/api/get_projects').reply(async config => {
    let params
    if (!config.country && !config.state) {
        params = {skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), type: config.type, keyword: config.value, startDate: config.startdate, endDate: config.enddate, userId: config.userId ?? null}
    } else {
        params = {skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), type: config.type, keyword: config.value, startDate: config.startdate, endDate: config.enddate, userId: config.userId ?? null, country: config.country, state: config.state}
    }
    const res = await getData('POST', `${process.env.REACT_APP_API_URL}/get_all_projects?sortBy=${config.sortBy}&sortOrder=${config.sortOrder}`, config.headers.Authorization, params)
    if (config.userId) {
        const newReponse = {
            ...res,
            data: {
            ...res.data,
            projects: res.data.projects.map(obj => {
                    return { ...obj, userId: config.userId }
                })
            }
        }

        return [200, newReponse.data]
    }

    return [200, res.data]
})

mock.onGet('/api/get_project_samples').reply(async config => {
    const {projectId} = config
    const param = {projectId}
    const res = await getData('POST', `${process.env.REACT_APP_API_URL}/get_all_project_samples`, config.headers.Authorization, param)
    return [200, res.data]
})
import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = [
  {
    responsive_id: '',
    id: 1,
    avatar: '10.jpg',
    full_name: "Soil Testing",
    post: 'Nuclear Power Engineer',
    email: 'kocrevy0@thetimes.co.uk',
    city: 'Krasnosilka',
    start_date: '09/23/2016',
    salary: 'don2898',
    age: '61',
    experience: '62ftuyrtyu3',
    status: 2
  },
  {
    responsive_id: '',
    id: 2,
    avatar: '1.jpg',
    full_name: 'Ground Moisture',
    post: 'VP Quality Control',
    email: 'bcoulman1@yolasite.com',
    city: 'Hinigaran',
    start_date: '05/20/2018',
    salary: 'mike2876',
    age: '63',
    experience: '61ftuzrtyu2',
    status: 2
  },
  {
    responsive_id: '',
    id: 3,
    avatar: '9.jpg',
    full_name: 'Crop Quality Index',
    post: 'Operator',
    email: 'sganderton2@tuttocitta.it',
    city: 'Golcowa',
    start_date: '03/24/2018',
    salary: 'sander2376',
    age: '66',
    experience: '61ftmrtytyu',
    status: 5
  },
  {
    responsive_id: '',
    id: 4,
    avatar: '10.jpg',
    full_name: 'Grain Quality',
    post: 'Cost Accountant',
    email: 'dcrossman3@google.co.jp',
    city: 'Paquera',
    start_date: '12/03/2017',
    salary: 'john2143',
    age: '22',
    experience: '61ftuzrsdre',
    status: 2
  },
  {
    responsive_id: '',
    id: 5,
    avatar: '',
    full_name: 'Corn Yield',
    post: 'Senior Cost Accountant',
    email: 'hnisius4@gnu.org',
    city: 'Lucan',
    start_date: '08/25/2017',
    salary: 'pant4534',
    age: '33',
    experience: '67ftyuiuyse',
    status: 2
  },
  {
    responsive_id: '',
    id: 6,
    avatar: '',
    full_name: 'Wheat Quality',
    post: 'Geologist',
    email: 'ghoneywood5@narod.ru',
    city: 'Maofan',
    start_date: '06/01/2017',
    salary: 'george7867',
    age: '61',
    experience: '67uytrgf562',
    status: 1
  },
  {
    responsive_id: '',
    id: 7,
    avatar: '',
    full_name: 'Maize Quality',
    post: 'Environmental Specialist',
    email: 'ediehn6@163.com',
    city: 'Lampuyang',
    start_date: '10/15/2017',
    salary: 'nick5634',
    age: '59',
    experience: '42qwe23dwds',
    status: 3
  }
]

mock.onGet('/api/samplestable/data').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1 } = config
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.filter(
    item =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      item.full_name.toLowerCase().includes(queryLowered) ||
      item.post.toLowerCase().includes(queryLowered) ||
      item.email.toLowerCase().includes(queryLowered) ||
      item.age.toLowerCase().includes(queryLowered) ||
      item.salary.toLowerCase().includes(queryLowered) ||
      item.start_date.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      allData: data,
      invoices: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})


import mock from '../mock'
import getData from '../apiCall'


mock.onGet('/api/get_countries_list').reply(async config => {
    const res = await getData('GET', `${process.env.REACT_APP_API_URL}/admin/statistics`, config.headers.Authorization)
    const data = []
    if (res.data && res.data.usersCountriesCount) {
        res.data.usersCountriesCount.forEach(element => {
            data.push({value: element._id, label: element._id})
        })
    }
    return [200, data]
})

mock.onGet('/api/get_states_list').reply(async config => {
    const res = await getData('GET', `${process.env.REACT_APP_API_URL}/admin/statistics`, config.headers.Authorization)
    const data = []
    if (res.data && res.data.unitedStatesCount) {
        res.data.unitedStatesCount.forEach(element => {
            data.push({value: element._id, label: element._id})
        })
    }
    return [200, data]
})
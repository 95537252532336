import mock from '../mock'
import jwt from 'jsonwebtoken'

const data = {
  users: [
    {
      id: 1,
      fullName: 'John Doe',
      username: 'johndoe',
      password: 'admin',
      avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
      email: 'admin@demo.com',
      role: 'admin',
      // ability: [
      //   {
      //     action: 'manage',
      //     subject: 'all'
      //   }
      // ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    },
    {
      id: 2,
      fullName: 'Jane Doe',
      username: 'janedoe',
      password: 'client',
      avatar: require('@src/assets/images/avatars/1-small.png').default,
      email: 'client@demo.com',
      role: 'client',
      ability: [
        {
          action: 'read',
          subject: 'ACL'
        },
        {
          action: 'read',
          subject: 'Auth'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    }
  ]
}

async function getUser(url = '', data) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MmZlM2Q3ZmY5YjkyZGUxMGM1ZGQzOGMiLCJpYXQiOjE2NjEzNDUzMzM5OTksImV4cCI6MTY2MTM0NTQyMDM5OX0.iwsnP94Me--8dPv_yBhDjDmnfrup8lcbSOj2pFmy2s43NCVp1GZDYFvonscgpiuehZuFqP4RfRF7MOrbyuefBmKwepV3UYWzuYREbXH_475dM9iKDHwAJCkFfybYHSXrDIIJMToIkKP2I69q3mtSfnSCoWSUm3ryFu5-x4KtW3T6xMRqO_geuN2oX1N8Ocb4KRsLKDTySoVHdv5LhjN0GsioTia_LjIzb3cjkNSBK5N7uSVQHr4D2ExAAFbIJ6NSDQWsnpucbqIE4ZrG_lgJmbahz7e6LeEp2zYcFumTlA3oqRDDl_2fSgjj6oga2o3MiOiXw-S8XWoMII74JJi1CNdB4iiEYvBsHrce9OOun9HWfr36XN92z0_1orgeHzZfrZo4TI7uDMUg3KkQRrNxvruqa576gAYLifrne2pV4I5_8erSsFi-EJjeMmlmMF6wPG8XI2O28zaYI2bt8cgLDuX2W9bJSVRVgjKIc8wsGgt6-gV_70pIgXFWk51AvxgU5mv8jeGmbFIcJNFjqld0yuPcbQP4rFGQKVXSRJbq6__5HDpEY4_6Xsi7vWL9Ll0V07isvqunRp5PRxksVuIz0hbnOzZCfcqEpqpiK7EARXN5eimbKXLeJpOlmGX13qgwSD1pLG289jAyytI3VJ6SxtSH0A32v9GZWiCYJ5TFXpw'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  })
  const param = response.json()
  return param// parses JSON response into native JavaScript objects
}

// ! These two secrets shall be in .env file and not in any other file
const jwtConfig = {
  secret: process.env.REACT_APP_SECRET,
  // refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
  expireTime: '10m'
  // refreshTokenExpireTime: '10m'
}

mock.onPost('/jwt/login').reply(async request => {
  const { email, password } = JSON.parse(request.data)
  const param = { email, password }
  
  const res = await getUser(`${process.env.REACT_APP_API_URL}/admin/login`, param)
  let error = {
    email: ['Something went wrong']
  }

  if (res.success) {
    try {
      const accessToken = res.data.token
      const userId = res.data.userId

      localStorage.setItem('userId', userId)

      const userData = { fullName: 'Microbometer Admin', username: 'Microbiometer Admin', id: userId, email: param.email }
      const response = {
        userData,
        accessToken
      }

      return [200, response]
    } catch (e) {
      error = e
    }
  } else {
    error = {
      email: ['Email or Password is Invalid']
    }
  }

  return [400, { error }]
})


mock.onPost('/jwt/register').reply(request => {
  if (request.data.length > 0) {
    const { email, password, username } = JSON.parse(request.data)
    const isEmailAlreadyInUse = data.users.find(user => user.email === email)
    const isUsernameAlreadyInUse = data.users.find(user => user.username === username)
    const error = {
      email: isEmailAlreadyInUse ? 'This email is already in use.' : null,
      username: isUsernameAlreadyInUse ? 'This username is already in use.' : null
    }

    if (!error.username && !error.email) {
      const userData = {
        email,
        password,
        username,
        fullName: '',
        avatar: null,
        role: 'admin',
        ability: [
          {
            action: 'manage',
            subject: 'all'
          }
        ]
      }

      // Add user id
      const length = data.users.length
      let lastIndex = 0
      if (length) {
        lastIndex = data.users[length - 1].id
      }
      userData.id = lastIndex + 1

      data.users.push(userData)

      const accessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })

      const user = Object.assign({}, userData)
      delete user['password']
      const response = { user, accessToken }

      return [200, response]
    } else {
      return [200, { error }]
    }
  }
})

mock.onPost('/jwt/refresh-token').reply(request => {
  const { refreshToken } = JSON.parse(request.data)

  try {
    const { id } = jwt.verify(refreshToken, jwtConfig.refreshTokenSecret)

    const userData = { ...data.users.find(user => user.id === id) }

    const newAccessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expiresIn })
    const newRefreshToken = jwt.sign({ id: userData.id }, jwtConfig.refreshTokenSecret, {
      expiresIn: jwtConfig.refreshTokenExpireTime
    })

    delete userData.password
    const response = {
      userData,
      accessToken: newAccessToken,
      refreshToken: newRefreshToken
    }

    return [200, response]
  } catch (e) {
    const error = 'Invalid refresh token'
    return [401, { error }]
  }
})

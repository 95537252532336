import mock from '../mock'

import { getData } from '../apiCall'

mock.onGet('/api/dashboard/stats').reply(async config => {
    const res = await getData('GET', `${process.env.REACT_APP_API_URL}/admin/statistics`, config.headers.Authorization)
      
    // if (res && res.data && res.data.unitedStatesCount) {
    //     const resultMap = new Map()
    //     res.data.unitedStatesCount.forEach(item => {
    //     const key = item._id.toLowerCase() // Convert _id to lowercase for case insensitivity
    //     if (resultMap.has(key)) {
    //         // If the key already exists in the map, add the count to the existing entry
    //         resultMap.set(key, resultMap.get(key) + item.count)
    //     } else {
    //         // If the key doesn't exist, create a new entry
    //         resultMap.set(key, item.count)
    //     }
    //     })
    //     let id
    //     // Convert the map back to an array of objects
    //     const resultArray = Array.from(resultMap, ([_id, count]) => ({ _id, count }))
    //     const arr = resultArray.map((item) => {
    //         id = item._id.charAt(0).toUpperCase() + item._id.slice(1)
    //         return {_id: id, count: item.count}
    //     })
    //     res.data.unitedStatesCount = arr
    // }
    return [200, res.data]
})